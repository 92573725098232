import React, { useState, useEffect } from "react";
import "./moncompte.css";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FcPhone, FcFeedback, FcRules } from "react-icons/fc";
import { CgLogOff } from "react-icons/cg";

import { BsListCheck } from "react-icons/bs";
import { RiAccountBoxFill } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function Moncompte() {
  const history = useHistory();
  const [tabOrder, setTabOrder] = useState("order");
  const [user, setUser] = useState("");
  const [reservation, setReservation] = useState([]);

  const [showEditInfos, setShowEditInfos] = useState(false);
  const [infos, setInfos] = useState([]);

  const handleSelect = (value) => {
    setTabOrder(value);
  };

  const handleInfos = (e) => {
    setInfos({ ...infos, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getUser();
  }, []);

  function getUser() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    };

    fetch("https://therott-node.herokuapp.com/client/profile", options)
      .then((response) => {
        return response.json();
      })
      .then(
        (data) => {
          setUser(data);
          setReservation(data.reservation);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const editInfos = (e) => {
    e.preventDefault();
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(infos),
    };

    fetch("https://therott-node.herokuapp.com/client/profile", options)
      .then((response) => {
        return response.json();
      })
      .then(
        (data) => {
          alert(data.message);
          getUser();
          setShowEditInfos(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  function displayEditInfos() {
    if (showEditInfos) {
      return (
        <div className="moncompte-card">
          <div className="display-edit-right">
            <div className="label-input">
              <label>Nom :</label>
              <br />
              <input
                type="text"
                name="lastname"
                placeholder={user.lastname}
                value={infos.lastname}
                onChange={handleInfos}
              ></input>
            </div>
            <div className="label-input">
              <label>Prénom :</label>
              <br />
              <input
                type="text"
                name="firstname"
                placeholder={user.firstname}
                value={infos.firstname}
                onChange={handleInfos}
              ></input>
            </div>
            <div className="label-input">
              <label>Tél :</label>
              <br />
              <input
                type="tel"
                name="phone"
                placeholder={user.phone}
                value={infos.phone}
                onChange={handleInfos}
              ></input>
            </div>
            <div className="label-input">
              <label>Email :</label>
              <br />
              <input
                type="email"
                placeholder={user.email}
                name="email"
                value={infos.email}
                onChange={handleInfos}
              ></input>
            </div>
            <div className="label-input">
              <label>Mot de passe :</label>
              <br />
              <input
                type="password"
                placeholder="Nouveau mot de passe"
                name="password"
                value={infos.password}
                onChange={handleInfos}
              ></input>
            </div>

            <div className="moncompte-link">
              <Button className="infos-button-edit" onClick={editInfos}>
                Valider
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }
  let count = 0;
  const showReservations = () =>
    reservation.map((value, index) => {
      return (
        <tr key={index}>
          <td className="hide-column">{(count += 1)}</td>
          <td>
            le {value.date} à {value.heure}
          </td>
          <td>{value.forfait}</td>
          <td>
            <BootstrapSwitchButton
              onlabel="Oui"
              offlabel="Non"
              checked={value.confirm}
              size="sm"
              disabled
            />
          </td>
          <td>
            <BootstrapSwitchButton
              onlabel="Oui"
              offlabel="Non"
              checked={value.session}
              size="sm"
              disabled
            />
          </td>
        </tr>
      );
    });

  function Tabselect() {
    switch (tabOrder) {
      case "order":
        return (
          <div className="title-moncompte">
            <div className="breadcrumb">
              <p>Dashboard / Mes réservations</p>
            </div>
            <div className="moncompte-orders">
              <h2>Mes réservations</h2>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="hide-column">#</th>
                  <th>Date / Heure</th>
                  <th>Forfait</th>
                  <th>Réservation validée</th>
                  <th>Session terminée</th>
                </tr>
              </thead>
              <tbody>{showReservations()}</tbody>
            </Table>
          </div>
        );

      case "infos":
        return (
          <div className="title-moncompte">
            <div className="breadcrumb">
              <p>Dashboard / Infos personnelles</p>
            </div>
            <div className="moncompte-infos">
              <h2>Infos personnelles</h2>
              <Row>
                <Col>
                  <div className="moncompte-card">
                    <div className="display-edit">
                      <p>Nom : {user.lastname}</p>
                      <p>Prénom : {user.firstname}</p>
                      <p>Tél : {user.phone}</p>
                      <p>Email: {user.email}</p>

                      <div className="moncompte-link">
                        <Button
                          className="infos-button"
                          onClick={() => {
                            setShowEditInfos(!showEditInfos);
                          }}
                        >
                          Modifier
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>{displayEditInfos()}</Col>
              </Row>
            </div>
          </div>
        );

      case "contact":
        return (
          <div className="title-moncompte">
            <div className="breadcrumb">
              <p>Dashboard / Contact</p>
            </div>
            <Row>
              <Col>
                <div className="moncompte-card">
                  <img src="/imgReact/magasin.jpg" alt="magasin" />
                  <div className="moncompte-contact">
                    <p>
                      <FcPhone className="fcicone" /> Tel: 06.07.23.70.22
                    </p>
                    <p>
                      <FcFeedback className="fcicone" /> Email:
                      contact@ruffa-karting.fr
                    </p>
                    <p>
                      <FcRules className="fcicone" />
                      Plateau de la sarrée
                    </p>
                    <p> route de gourdon</p>
                    <p>06620 le bar sur loup</p>
                  </div>
                </div>
              </Col>
              <Col className="contact-form">
                <img src="/imgReact/rc-plan.png" alt="ruffa-karting-map" />

                <form
                  action="mailto:xxx@xxx.fr subject=form"
                  name="envoi"
                  method="post"
                  encType="text/plain"
                >
                  <input
                    type="text"
                    placeholder="Nom"
                    name="nom"
                    id="nom1"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email1"
                    required
                  />
                  <input
                    type="tel"
                    placeholder="Téléphone"
                    name="tel"
                    id="tel1"
                    required
                  />
                  <textarea
                    type="text"
                    placeholder="Message"
                    name="message"
                    id="message1"
                  ></textarea>
                  <div className="form-button">
                    <Button type="submit" value="envoyer">
                      Envoyer
                    </Button>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        );

      default:
    }
  }

  function Disconnect() {
    let nom = localStorage.getItem("token");
    if (nom) {
      localStorage.removeItem("token");
      history.push("/");
    }
  }

  return (
    <div>
      <Header />
      <div className="moncompte-body">
        <Row>
          <Col className="background-col">
            <div className="monCompte-nav">
              <h3
                onClick={() => {
                  handleSelect("order");
                }}
              >
                <BsListCheck /> <p>Mes réservations</p>
              </h3>
              <h3
                onClick={() => {
                  handleSelect("infos");
                }}
              >
                <RiAccountBoxFill /> <p>Infos personnelles</p>
              </h3>
              <h3
                onClick={() => {
                  handleSelect("contact");
                }}
              >
                <HiOutlineMail /> <p>Nous Contacter</p>
              </h3>
              <h3 onClick={Disconnect}>
                <CgLogOff /> <p>Déconnexion</p>
              </h3>
            </div>
          </Col>
          <Col>
            <div className="tabSelect">{Tabselect()}</div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}

export default Moncompte;
