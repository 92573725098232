import React from "react";
import "./footer.css";
import { FiPhone } from "react-icons/fi";
import { FiMail } from "react-icons/fi";

function Footer() {
  return (
    <footer id="contact">
      <div className="container">
        <div className="footer-contact">
          <p>
            RUFFA-KARTING, Plateau de la sarrée Route de Gourdon 06620 Le
            Bar-Sur-Loup{" "}
          </p>
          <a href="mailto:contact@ruffa-karting.fr">
            <FiMail /> contact@ruffa-karting.fr
          </a>
          <br />
          <br />
          <a href="tel:0607237022">
            <FiPhone /> 06.07.23.70.22
          </a>
          <br />
          <a href="tel:0692604254">
            <FiPhone /> 04.92.60.42.54
          </a>
        </div>
        <br />
        <div className="copyright">
          &copy; Copyright 2021 <strong>Ruffa Karting</strong>. All Rights
          Reserved
        </div>
      </div>
    </footer>
  );
}

export default Footer;
