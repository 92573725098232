import React, { useEffect, useState } from "react";
import "./adminpanel.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Table, Button } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function Adminpanel() {
  const [client, setClient] = useState([
    {
      reservation: [{}],
    },
  ]);

  const [tabOrder, setTabOrder] = useState("reservation");

  const handleSelect = (value) => {
    setTabOrder(value);
  };

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    };

    fetch("https://therott-node.herokuapp.com/admin/reservation-list", options)
      .then((response) => {
        return response.json();
      })
      .then(
        (data) => {
          setClient(data);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  let count = 0;

  const clientAccount = () =>
    client.map((value, index) => {
      return (
        <tr key={index}>
          <td className="hide-column">{(count += 1)}</td>
          <td>{value.lastname}</td>
          <td>{value.firstname}</td>
          <td>{value.email}</td>
          <td>{value.phone}</td>
        </tr>
      );
    });

  const clientReservation = () =>
    client.map((value, index) => {
      return (
        <tr key={index}>
          <td className="hide-column">{(count += 1)}</td>
          <td>{value.lastname}</td>
          <td>{value.phone}</td>
          <td>
            {value.reservation.map((data) => {
              if (!data.forfait) {
                return <div className="red-line">Erreur</div>;
              } else {
                return <div className="margin-table-1">{data.forfait}</div>;
              }
            })}
          </td>
          <td>
            {value.reservation.map((data) => {
              if (!data.date) {
                return <div className="red-line">Erreur</div>;
              } else {
                return <div className="margin-table-1">{data.date}</div>;
              }
            })}
          </td>
          <td>
            {value.reservation.map((data) => {
              if (data.session === true) {
                return (
                  <div className="margin-table">
                    <BootstrapSwitchButton
                      onlabel="Oui"
                      offlabel="Non"
                      checked={data.session}
                      size="xs"
                    />
                  </div>
                );
              } else {
                return (
                  <div className="margin-table">
                    <BootstrapSwitchButton
                      onlabel="Oui"
                      offlabel="Non"
                      checked={data.session}
                      size="xs"
                    />
                  </div>
                );
              }
            })}
          </td>
        </tr>
      );
    });

  function Tabselect() {
    switch (tabOrder) {
      case "listing":
        return (
          <div className="admin-content">
            <div className="breadcrumb">
              <p>Dashboard / AdminPanel</p>
            </div>
            <Table striped bordered hover className="admin-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                </tr>
              </thead>
              <tbody>{clientAccount()}</tbody>
            </Table>
          </div>
        );

      case "reservation":
        return (
          <div className="admin-content">
            <div className="breadcrumb">
              <p>Dashboard / Réservations</p>
            </div>
            <Table striped bordered hover className="admin-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th>Téléphone</th>
                  <th>Forfait</th>
                  <th>Date / Heure</th>
                  <th>Validation</th>
                </tr>
              </thead>
              <tbody>{clientReservation()}</tbody>
            </Table>
          </div>
        );

      default:
    }
  }

  return (
    <section>
      <Header />
      <div className="admin-navbar">
        <Button
          className="listing-client"
          onClick={() => {
            handleSelect("listing");
          }}
        >
          Listing Client
        </Button>
        <Button
          className="reservation-client"
          onClick={() => {
            handleSelect("reservation");
          }}
        >
          Réservations
        </Button>
      </div>
      {Tabselect()}
      <Footer />
    </section>
  );
}

export default Adminpanel;
