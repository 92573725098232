import React from "react";
import "./licence.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Col, Row } from "react-bootstrap";

function Licence() {
  return (
    <section>
      <Header />
      <div className="licence-content">
        <h1 className="title">Obtenir sa licence journalière</h1>
        <Row className="licence-container">
          <Col>
            <h4>Ancien licencié</h4>
            <p>
              Connectez-vous ou cliqué sur " Retrouver mes identifiants "
              (indiquer votre nom, prénom et date de naissance).
            </p>
            <p>Vous recevez par mail vos identifiants.</p>
          </Col>
          <Col>
            <h4>Première licence FFSA</h4>
            <p>Cliquez sur " Nouveau licencié " en bas de l'ecran.</p>
            <p>
              Indiquez votre nom, prénom et date de naissance, cliquez "
              Soumettre la demande ", puis cliquez sur " Continuer votre
              inscription ".
            </p>
            <p>Complétez les champs demandés puis cliquez sur " Suivant "</p>
            <p>Une fois le compte crée connectez-vous</p>
          </Col>
        </Row>
        <hr />
        <div>
          <ol className="tuto">
            <li>
              Rendez-vous sur le{" "}
              <a
                href="https://licence.ffsa.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                site de la FFSA
              </a>{" "}
              (le prix de la licence jounalière est de 32 euros)
            </li>
            <br />
            <li>
              Cliquez sur <strong>" Nouveau titre de participation "</strong>{" "}
              (onglet vert),
            </li>
            <br />
            <li>
              Vérifiez l'exactitude des informations et cliquez sur{" "}
              <strong>" Continuer "</strong>
            </li>
            <br />
            <li>
              Sélectionnez la discipline <strong>" Karting "</strong> puis
              renseignez la date du jour de roulage souhaité.
            </li>
            <br />
            <li>
              Choisissez <strong>" ASK KART CLUB 06 "</strong>, pour cela
              renseignez dans le cadre de recherche de club le code{" "}
              <strong>" 0773 "</strong> et cliquez sur " Rechercher ".
            </li>
            <br />
            <li>
              Sélectionnez <strong>" ASK KART CLUB 06 "</strong>et cliquez sur{" "}
              <strong>" Choix "</strong>.
            </li>
            <br />
            <li>
              Sélectionnez ensuite votre choix de titre de participation, soit
              <strong>
                " TPNEJK - NATIONAL ENTRAINEMENT JOURNEE KARTING "
              </strong>
              , y joindre votre pièce d'identité.
            </li>
            <br />
            <li>
              Cliquez sur <strong>" Envoyer les documents "</strong> puis sur{" "}
              <strong>" Suivant "</strong>.
            </li>
            <br />
            <li>
              Sélectionnez{" "}
              <strong>
                " Je reconnais avoir téléchargé et pris connaissance d'un
                exemplaire des notices ... "
              </strong>
              , ainsi que votre choix de garantie complémentaire.
            </li>
            <br />
            <li>
              Cliquez sur <strong>" Actualiser le panier "</strong> et enfin{" "}
              <strong>" Finaliser ma demande "</strong>.
            </li>
            <br />
            <li>
              Un panier récapitulatif apparait, cliquez sur{" "}
              <strong>" Suivant "</strong>et sur{" "}
              <strong>" Pousuivre ma demande de Licence "</strong>.
            </li>
            <br />
            <li>
              Pour finir, cliquez sur{" "}
              <strong>" Paiement de votre Licence "</strong>, cochez{" "}
              <strong>" Carte bancaire "</strong>puis cliquez sur{" "}
              <strong>" Continuer "</strong>, procéder au règlement en allant
              jusqu'à cliquer sur <strong>" Valider "</strong>et vous avez fini.
            </li>
          </ol>
        </div>
        <div className="licence-footer">
          <p>En cas de difficulté n'hésitez pas à me contacter.</p>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default Licence;
