import React from "react";
import "./preview.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Col, Row } from "react-bootstrap";

function Preview() {
  return (
    <section>
      <Header />
      <div className="preview-content">
        <Row className="preview-container">
          <Col>
            <div>
              <img id="fk" src="/imgReact/logo_fka.png" alt="logo" />
            </div>
          </Col>
          <Col>
            <div>
              <h1>Fun Kart et Ruffa-Karting </h1>
              <p>
                sont deux entreprises indépendante de location de karting, qui
                vous accueil sur la piste de la sarrée à Le Bar-Sur-Loup. À 30
                minutes seulement de Cannes, Nice ou Antibes, c'est la seule
                piste de karting des Alpes-Maritimes. Location de karting loisir
                (4T) ou de karting de compétition (2T) et disposant également
                d'un restaurant sur place.
              </p>
            </div>
          </Col>

          <Col>
            <div>
              <img id="rc" src="/imgReact/logo.png" alt="logo" />
            </div>
          </Col>
        </Row>
        <Row className="centerRow">
          <Col className="alignCol">
            <Col className="colSize">
              <div className="diagonale"></div>
              <img className="kart" src="/imgReact/kart-enfant.png" alt="" />
              <p className="textAbsolute">4 T</p>
              <h5>Kart Enfant</h5>
              <div className="description">
                <div className="descriptionFlex">
                  <img src="/imgReact/stopwatch.png" alt="logo" />
                  <p>5 min</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/distance.png" alt="logo" />
                  <p>Circuit 100m</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/scale.png" alt="logo" />
                  <p>1m30 et 7 ans min</p>
                </div>

                <div className="diagonale2">
                  <p>L'unité: 7.50€ TTC</p>
                </div>
                <div className="diagonale3">
                  <img src="/imgReact/ticket.png" alt="logo" />
                  <p>Pour l'achat de 10 tickets de 5 min</p>
                </div>
                <div className="diagonale4">
                  <p>L'unité: 6.00€ TTC</p>
                </div>
              </div>
            </Col>
            <Col className="colSize">
              <div className="diagonale"></div>
              <img className="kart" src="/imgReact/kart-270cc.png" alt="" />
              <p className="textAbsolute">4 T</p>
              <h5>Kart 9 chevaux</h5>
              <div className="description">
                <div className="descriptionFlex">
                  <img src="/imgReact/stopwatch.png" alt="logo" />
                  <p>10 min</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/distance.png" alt="logo" />
                  <p>Circuit 350m</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/scale.png" alt="logo" />
                  <p>1m50 et 11 ans min</p>
                </div>
                <div className="diagonale2">
                  <p>L'unité: 20.00€ TTC</p>
                </div>
                <div className="diagonale3">
                  <img src="/imgReact/ticket.png" alt="logo" />
                  <p>Pour l'achat de 5 tickets de 10 min</p>
                </div>
                <div className="diagonale4">
                  <p>L'unité: 16.00€ TTC</p>
                </div>
              </div>
            </Col>
            <Col className="colSize">
              <div className="diagonale"></div>
              <img className="kart" src="/imgReact/kart-390cc.png" alt="" />
              <p className="textAbsolute">4 T</p>
              <h5>Kart 13 chevaux</h5>
              <div className="description">
                <div className="descriptionFlex">
                  <img src="/imgReact/stopwatch.png" alt="logo" />
                  <p>15 min</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/distance.png" alt="logo" />
                  <p>Circuit 1180m</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/scale.png" alt="logo" />
                  <p>16 ans min</p>
                </div>
                <div className="diagonale2">
                  <p>L'unité: 44.00€ TTC</p>
                </div>
                <div className="diagonale3">
                  <img src="/imgReact/ticket.png" alt="logo" />
                  <p>Pour l'achat de 5 tickets de 15 min</p>
                </div>
                <div className="diagonale4">
                  <p>L'unité: 36.00€ TTC</p>
                </div>
              </div>
            </Col>
          </Col>

          <Col className="alignColR">
            <Col className="colSize">
              <div className="diagonaleR"></div>
              <img className="kart" src="/imgReact/kart-enfant.png" alt="" />
              <p className="textAbsolute">2 T</p>
              <h5>Kart Compétition 30 chevaux</h5>
              <div className="description">
                <div className="descriptionFlex">
                  <img src="/imgReact/stopwatch.png" alt="logo" />
                  <p>15 min</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/distance.png" alt="logo" />
                  <p>Circuit 1180m</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/scale.png" alt="logo" />
                  <p>13 ans min</p>
                </div>

                <div className="diagonale2R">
                  <p>L'unité: 85.00€ TTC</p>
                </div>
                <div className="diagonale3R">
                  <ul>
                    <li>Réservation préférable</li>
                    <li>
                      Une licence FFSA sera obligatoire ainsi qu'un chéque de
                      caution.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col className="colSize">
              <div className="diagonaleR"></div>
              <img className="kart" src="/imgReact/kart-enfant.png" alt="" />
              <p className="textAbsolute">2 T</p>
              <h5>Kart Compétition 30 chevaux</h5>
              <div className="description">
                <div className="descriptionFlex">
                  <img src="/imgReact/stopwatch.png" alt="logo" />
                  <p>30 min</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/distance.png" alt="logo" />
                  <p>Circuit 1180m</p>
                </div>
                <div className="descriptionFlex">
                  <img src="/imgReact/scale.png" alt="logo" />
                  <p>13 ans min</p>
                </div>

                <div className="diagonale2R">
                  <p>L'unité: 170.00€ TTC</p>
                </div>
                <div className="diagonale3R">
                  <ul>
                    <li>Réservation préférable</li>
                    <li>
                      Une licence FFSA sera obligatoire ainsi qu'un chéque de
                      caution.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
        <Row className="rowFooter">
          <Col>
            <p>
              Pour plus d'info veuillez-vous rendre sur: https://fun-karting.com
            </p>
            <p>Tél: 04.92.42.48.08</p>
          </Col>
          <Col>
            <p>
              Pour plus d'info veuillez-vous rendre sur:
              https://ruffa-karting.fr
            </p>
            <p>Tél: 06.07.23.70.22</p>
          </Col>
        </Row>
      </div>
      <Footer />
    </section>
  );
}

export default Preview;
