import React, { useState } from "react";
import "./account.css";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

function Account(props) {
  const [newAccount, setnewAccount] = useState({});

  const [account, setAccount] = useState({});
  const history = useHistory();

  const [modal, setModal] = useState(false);

  const handleNewAccount = (e) => {
    setnewAccount({ ...newAccount, [e.target.name]: e.target.value });
  };

  const addAccount = (e) => {
    e.preventDefault();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newAccount),
    };
    fetch("https://therott-node.herokuapp.com/client/create", options)
      .then((response) => {
        return response.json();
      })
      .then(
        (data) => {
          alert(data.message);
          props.handleCloseLogin();
          setnewAccount({});
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleAccount = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };
  const loggingIn = (e) => {
    e.preventDefault();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(account),
    };
    fetch("https://therott-node.herokuapp.com/client/login", options)
      .then((response) => {
        return response.json();
      })
      .then(
        (data) => {
          alert(data.message);
          if (data.token) {
            localStorage.setItem("token", data.token);
            localStorage.setItem("secure", data.secure);
            localStorage.setItem("expire", Date.now());
            props.handleCloseLogin();
            history.push("/reservation");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  function displayModalInfo() {
    if (modal === false) {
      return (
        <div className="border-none">
          <h1>Connexion</h1>
          <input
            type="email"
            name="email"
            onChange={handleAccount}
            value={account.email}
            required
            placeholder="email"
          ></input>
          <input
            type="password"
            name="password"
            onChange={handleAccount}
            value={account.password}
            required
            placeholder="mot de passe"
          ></input>
          <div className="button-login">
            <Button type="submit" onClick={loggingIn}>
              Se connecter
            </Button>
          </div>
          <p>Vous n'avez pas encore de compte ?</p>
          <Link
            replace
            onClick={() => {
              setModal(!modal);
            }}
          >
            Créez-en un ici
          </Link>
        </div>
      );
    } else if (modal) {
      return (
        <div className="border-none-create">
          <h1>Création</h1>
          <Row>
            <Col>
              <input
                type="text"
                placeholder=" Nom"
                onChange={handleNewAccount}
                name="lastname"
                value={newAccount.lastname}
              ></input>
              <input
                type="text"
                placeholder=" Prénom"
                onChange={handleNewAccount}
                name="firstname"
                value={newAccount.firstname}
              ></input>
              <input
                type="text"
                placeholder=" Tél"
                onChange={handleNewAccount}
                name="phone"
                value={newAccount.phone}
              ></input>
              <input
                type="email"
                placeholder=" Email"
                onChange={handleNewAccount}
                name="email"
                value={newAccount.email}
              ></input>
              <input
                type="password"
                placeholder=" Mot de passe"
                onChange={handleNewAccount}
                name="password"
                value={newAccount.password}
              ></input>
            </Col>
            <Col>
              <div className="button-login-new">
                <Button onClick={addAccount}>S'inscrire</Button>
              </div>
              <p>Vous possédez déjà un compte ?</p>
              <Link
                replace
                onClick={() => {
                  setModal(!modal);
                }}
              >
                Connectez-vous ici
              </Link>
            </Col>
          </Row>
        </div>
      );
    }
  }

  return <div className="account-body">{displayModalInfo()}</div>;
}
export default Account;
