import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import Accueil from "./Components/Accueil/Accueil";
import Location from "./Components/Location/Location";
import Licence from "./Components/Licence/Licence";
import KartOccasion from "./Components/KartOccasion/KartOccasion";
import ScrollUp from "./assets/ScroolUp/ScrollUp";
import "bootstrap/dist/css/bootstrap.min.css";
import Moncompte from "./Components/Location/Moncompte/Moncompte";
import Account from "./Components/Location/Account/Account";
import Reservation from "./Components/Location/Reservation/Reservation";
import Adminpanel from "./Components/AdminPanel/Adminpanel";
import Preview from "./Components/Location-preview/Preview";

function App() {
  const [adminToken, setAdminToken] = useState();

  useEffect(() => {
    getToken();
    expireToken();
  }, []);

  function expireToken() {
    const expire = localStorage.getItem("expire");
    // 86400000 =  1 day
    const date = Date.now() - 86300000;

    if (date > expire) {
      localStorage.removeItem("token");
    } else {
      return;
    }
  }

  function getToken() {
    const token = localStorage.getItem("token");
    const secure = localStorage.getItem("secure");

    if (token && secure === "SecuredAreaAdmin") {
      setAdminToken(token);
      setAdminToken(secure);
    }
  }

  function renderRoutes() {
    if (adminToken) {
      return [<Route path="/ruffa-admin" component={Adminpanel} exact />];
    } else {
      return <Route path="/" component={Accueil} />;
    }
  }

  return (
    <HashRouter basename="/">
      <div className="main-content">
        <ScrollUp>
          <Switch>
            <Route path="/" component={Accueil} exact />
            <Route path="/location" component={Location} exact />
            
            <Route path="/licence" component={Licence} exact />
            <Route path="/moncompte" component={Moncompte} exact />
            <Route path="/account" component={Account} exact />
            <Route path="/reservation" component={Reservation} exact />
            <Route path="/preview" component={Preview} exact />
            {renderRoutes()}
          </Switch>
        </ScrollUp>
      </div>
    </HashRouter>
  );
}

export default App;
